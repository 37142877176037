//////画像切り替え
$(function () {
  var width = $(window).width();
  if( width < 667 ){
    $("img").each(function(){
      $(this).attr("src", $(this).attr("src").replace("_pc","_sp"));
    })
  }
});
//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
});
//////スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 1500 ;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});
//////ナビゲーション
$(function(){
  $('.menu_trigger').on('click',function(){
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
    $('.header_contents').toggleClass('nav_active');
  });
  $('.header_nav_contents a').on('click', function(){
    if (window.innerWidth <= 1200) {
      $('.menu_trigger').click();
      }
  });	
  $(window).on('resize', function() {
    if( 'none' == $('.header_nav_contents').css('display') ){
      $('.header_nav_contents').attr('style','');
    }
  });
});
//////header
$(function() {
  var $win = $(window),
  animationClass = 'is_animation';
  $win.on('load scroll', function() {
  var value = $(this).scrollTop();
  if ( value > 100 ) {
    $('.header_inner').addClass('is_animation');
  } else {
    $('.header_inner').removeClass('is_animation');
  }
  });
});
$(function(){
  var startPos = 0, winScrollTop = 0;
  $(window).on('scroll', function () {
      winScrollTop = $(this).scrollTop();
      if (winScrollTop >= startPos && winScrollTop > 100) { 
          $('header').addClass('hide');
      } else {
          $('header').removeClass('hide');
      }
      startPos = winScrollTop;
  });
});
//////アニメーション
$(function() {
  AOS.init({
    offset: 50,
    duration: 1300,
  });
});



